.authorization {
  font-family: Commissioner, sans-serif;
  background-color: white;
  border-radius: 16px;
  min-height: 545px;
  @media (min-width: 320px) {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  @media (min-width: 768px) {
    width: 416px;
    margin: 96px auto 0;
    height: fit-content;
  }
  @media (min-width: 1366px) {
    margin: 0 auto 0;
  }
  @media (min-width: 1920px) {
    margin: 96px auto 0;
  }
  .MuiFormControl-root.MuiFormControl-fullWidth {
    margin-bottom: 16px;
    border-radius: 8px;
  }
  //.MuiTabs-flexContainer {
  //  border: 1px solid #c8c9ca;
  //  :first-child {
  //    margin-right: 4px;
  //  }
  //
  //  padding: 4px;
  //  border-radius: 6px;
  //  justify-content: center;
  //}
  //
  //.MuiTabs-flexContainer .MuiTab-root {
  //  flex: 1;
  //}
  //.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  //  border-radius: 5px;
  //  text-transform: none;
  //  padding: 10px 8px 16px 8px;
  //  font-size: 14px;
  //  font-weight: 550;
  //  transition: all 0.5s cubic-bezier(0.42, 0, 0.97, 0.33);
  //}
  //
  //.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  //  background-color: #383c40;
  //  color: white;
  //}
  //
  //.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary:not(.Mui-selected) {
  //  background-color: transparent;
  //  color: #383c40;
  //}
  //
  //.MuiTabs-indicator {
  //  background-color: transparent !important;
  //}
  //.MuiFormControl-root.MuiFormControl-fullWidth:focus {
  //  margin-bottom: 16px;
  //  border-radius: 8px;
  //}
  //.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  //  background-color: #f4f4f4;
  //  border-radius: 8px;
  //  padding-right: 0;
  //  cursor: pointer;
  //} //Не стирать
  //
  //.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before {
  //  border: transparent 1px solid;
  //  border-radius: 8px;
  //}
  //.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::after {
  //  border: 1px solid transparent;
  //  border-radius: 8px;
  //}
  //.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root {
  //  border: solid 1px transparent !important;
  //  padding-right: 0;
  //  padding-left: 0;
  //} //Input c adorment
  //.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root.Mui-error {
  //  //border: solid 1px $workki-red-line !important;
  //  background-color: $workki-red-light !important;
  //} //Input c adorment
  //.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root.Mui-focused,
  //.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root:hover {
  //  border: solid 1px #c8c9ca !important;
  //  cursor: pointer;
  //}
  //.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root.Mui-error.Mui-focused,
  //.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root.Mui-error:hover {
  //  border: solid 1px $workki-red-line !important;
  //  background-color: $workki-red-light !important;
  //}
  //.css-17gnpz5-MuiInputBase-root-MuiFilledInput-root.Mui-focused,
  //.css-17gnpz5-MuiInputBase-root-MuiFilledInput-root:hover {
  //  border: solid 1px #c8c9ca !important;
  //  cursor: pointer;
  //} //Input со start и endAdornment
  //.css-17gnpz5-MuiInputBase-root-MuiFilledInput-root.Mui-error.Mui-focused,
  //.css-17gnpz5-MuiInputBase-root-MuiFilledInput-root.Mui-error:hover {
  //  border: solid 1px $workki-red-line !important;
  //  background-color: $workki-red-light !important;
  //}
  //.css-17gnpz5-MuiInputBase-root-MuiFilledInput-root.Mui-error {
  //  background-color: $workki-red-light !important;
  //}
  //
  //.MuiFormHelperText-root {
  //  margin-left: 0;
  //  color: $workki-red-main;
  //}
  //
  ////.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root:hover {
  ////  border: solid 1px #c8c9ca !important;
  ////  cursor: pointer;
  ////}
  //
  //.css-10botns-MuiInputBase-input-MuiFilledInput-input:focus {
  //  border: 1px #c8c9ca solid !important;
  //  border-radius: 8px;
  //} //Работало до adorment
  //.css-9mc7sf-MuiInputAdornment-root {
  //  margin-right: 0 !important;
  //}
  //
  //label {
  //  padding-left: 0;
  //}
  //
  //label.Mui-focused {
  //  color: $workki-dark-middle !important;
  //}
  //
  //label.Mui-focused,
  //.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root,
  //.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  //  color: $workki-dark-middle !important;
  //}
  //label.Mui-focused:before,
  //.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root,
  //.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  //  color: mediumvioletred !important;
  //} //Разобраться потом

  &__tabs-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    & > img {
      width: 135px;
      height: 24px;
    }

    margin: 8px 32px;
    &__logo-wrapper {
      padding: 18px 12px 24px 12px;
      display: flex;
      justify-content: center;
    }
    &__phone-logo-wrapper {
      padding: 18px 12px 0 12px;
      display: flex;
      justify-content: center;
    }
  }
  &__caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 16px 32px 16px 32px;
    :first-child {
      font-size: 22px;
      line-height: 24px;
      margin: 0 0 8px 0;
      font-weight: 500;
    }
    :nth-child(2) {
      margin: 0;
      font-weight: 300;
    }
    &__phoneWrapper {
      margin-top: 16px !important;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      flex-direction: row;
      & > span {
        margin-right: 12px;
      }
      &:nth-child(2) {
        color: $workki-dark-middle;
      }
    }
  }
  &__phone-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 16px 32px 16px 32px;
    & > p {
      font-size: 22px;
      line-height: 24px;
      margin: 0 0 16px 0;
      font-weight: 500;
    }
    &__phoneWrapper {
      flex-direction: column;
    }
    &__phoneAuthWrapper {
      flex-direction: row;
      :first-child {
        margin-right: 12px;
      }
      :nth-child(2) {
        cursor: pointer;
      }
    }
    &__phoneWrapper,
    &__phoneAuthWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $workki-dark-middle;
      & > span {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
      }
      :first-child,
      :nth-child(2) {
        color: $workki-dark-fill;
        margin-top: 2px;
      }
      :nth-child(3) {
        cursor: pointer;
        margin-top: 8px;
        :hover {
          color: $workki-light-line;
        }
      }
    }

    &__successWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > span {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
      }
      :first-child {
        font-weight: 500;
        font-size: 22px;
        line-height: 24px;
      }
      :first-child,
      :nth-child(2) {
        color: $workki-dark-main;
        margin-top: 2px;
      }
      :nth-child(3) {
        cursor: pointer;
        margin-top: 16px;
        color: $workki-dark-secondary;
      }
    }
  }
  &__phone-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 0 16px 0;
    & > p {
      font-weight: 400;
    }
    & > p > span {
      color: $workki-red-main;
    }
    &__bubble {
      background-color: $workki-light-light1;
      padding: 8px 8px 8px 16px;
      border-radius: 300px;
      width: 260px;
      &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
    &__call {
      margin-left: 4px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $workki-dark-middle;
      :first-child {
        font-family: Commissioner, sans-serif;
        font-weight: 300;
        font-size: 16px;
        margin: 0;
        & > span {
          color: $workki-red-main;
        }
      }
      :nth-child(2) {
        font-size: 11px;
        margin: 0;
        font-weight: 300;
      }
    }
    &__profile {
      display: flex;
      justify-items: flex-end;
    }
    &__phone-fields {
      margin: 16px 0 0 0;
      flex-wrap: nowrap;
    }
    &__field-wrapper {
      display: flex;
      flex-direction: column;
    }
    &__field {
      font-weight: 100;
      font-size: 32px;
      text-align: center;
      color: $workki-dark-main;
      width: 48px;
      height: 56px;
      border-radius: 8px;
      border: 1px solid $workki-light-line;
      padding: 10px 8px 14px 8px;
      margin-right: 8px;
      align-items: center;
      &:focus {
        background-color: $workki-light-light05;
      }
    }
    &__error {
      margin-top: 4px;
      color: $workki-red-main;
    }
  }
  &__fields-wrapper {
    padding: 16px 32px;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    height: 56px;
    background-color: $workki-dark-fill;
    text-transform: none;
    border-radius: 8px;
    transition: none;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained:hover,
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained:active {
    background-color: $workki-dark-main;
    color: white;
    transition: none !important;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained:disabled {
    background-color: $workki-light-line;
    color: white;
  }
  &__common-error {
    background-color: $workki-red-light;
    color: $workki-red-fill;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    margin: 8px 0;
    padding: 4px;
    align-items: center;
    font-weight: 400;
    :nth-child(2) {
      margin-top: -2px;
    }
  }
  &__footer {
    &__links-wrapper {
      padding: 8px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      & > a {
        color: $workki-dark-main;
        text-decoration: none;
        margin-bottom: 16px;
        font-size: 14px;
        cursor: pointer;
        font-weight: 300;
      }
      &__timer-disabled {
        cursor: not-allowed;
      }
      &__timer-active {
        cursor: pointer;
        :hover {
          color: $workki-dark-secondary;
        }
      }
      & > a:hover {
        color: $workki-dark-secondary;
      }
    }
    &__policy-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      /* Убираем границы и добавляем заливку фона для чекбокса */
      .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        margin-right: 6px;
        border: none;
        box-shadow: 0 1px 3px 0 #00000026 inset;
        color: transparent;
        background-color: $workki-light-light1 !important;
        & > svg {
          width: 22px;
          height: 22px;
          border-radius: 4px;
        }
      }
      .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:hover {
        background-color: $workki-light-light2 !important;
      }
      & > p {
        margin-top: 2px;
      }

      /* Изменяем стили для выбранного (checked) и частично выбранного (indeterminate) состояний чекбокса */
      .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
      .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
        color: $workki-dark-fill;
        border-radius: 4px;
      }
      .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked:hover {
        color: $workki-dark-main;
        border-radius: 4px;
      }
      .css-i4bv87-MuiSvgIcon-root {
        border: none !important; /* Используем !important, чтобы переопределить стандартные стили */
      }
    }
    &__policy,
    &__registration-policy {
      color: #8f9295;
      font-size: 12px;
      line-height: 16px;
      font-weight: 370;
      text-align: center;
      & > a {
        color: #8f9295;
        text-decoration: 0.5px underline;
      }
    }
    &__registration-policy {
      color: $workki-dark-main;
      text-align: left;
      & > a {
        color: $workki-dark-main;
      }
    }
  }
  &__bottom {
    margin-top: 64px;
    display: flex;
    justify-content: center;
  }
  &__forget-password-wrapper {
    background: #383c4080;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 12;
    top: 0;
    left: 0;
    display: flex;
    @media (min-width: 320px) {
      align-items: flex-end;
    }
    @media (min-width: 768px) {
      align-items: center;
    }

    justify-content: center;
  }
  &__forget-password {
    margin-top: -200px;
    padding: 24px 16px;
    background-color: $workki-light-white;
    color: $workki-dark-main;
    border: none;
    border-radius: 16px;
    @media (min-width: 320px) {
      width: 100%;
      padding: 16px;
    }
    @media (min-width: 768px) {
      width: 464px;
      padding: 24px;
    }
    &__description {
      font-weight: 350;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
    &__title {
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
    }
    &__top-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &__confirmed {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &__ghost-input {
    border: none;
  }
}
