.profile-avatar-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: #262629;
  height: 100vh;
  width: 100vw;
  @media (max-width: 747px) {
    flex-direction: column;
    align-items: center;
  }

  &__container {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__close {
    top: 0;
    right: 0;
    position: absolute;
    margin: 40px 40px 0 0;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    width: 30px;
    height: 30px;
    outline: none;
    border: unset;
    background: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.39318 1.27186C2.80739 0.686073 1.85765 0.686073 1.27186 1.27186C0.686073 1.85765 0.686073 2.80739 1.27186 3.39318L11.8787 14L1.2723 24.6064C0.686511 25.1922 0.686511 26.1419 1.2723 26.7277C1.85808 27.3135 2.80783 27.3135 3.39362 26.7277L14 16.1213L24.6064 26.7277C25.1922 27.3135 26.1419 27.3135 26.7277 26.7277C27.3135 26.1419 27.3135 25.1922 26.7277 24.6064L16.1213 14L26.7281 3.39318C27.3139 2.80739 27.3139 1.85765 26.7281 1.27186C26.1424 0.686073 25.1926 0.686073 24.6068 1.27186L14 11.8787L3.39318 1.27186Z' fill='white'/%3E%3C/svg%3E%0A")
      no-repeat center;
    background-size: 30px 30px;
    @media (max-width: 747px) {
      width: 24px;
      height: 24px;
      background-size: 24px 24px;
      padding: 10px;
      margin: 5px 5px 0 0;
      box-sizing: content-box;
    }
  }

  &__area {
    max-width: 100vw;
    padding-top: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    height: 100%;
    width: 100%;
    margin-bottom: -60px;
    @media (max-width: 1365px) {
      height: fit-content;
      width: fit-content;
      margin-bottom: 0;
    }
    @media (max-width: 747px) {
      height: fit-content;
      width: fit-content;
      padding-top: 60px;
    }
  }

  &__editor-placeholder {
    width: 134px;
    height: 134px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__canvas {
    max-width: 100vw;
    max-height: 60vh;
    object-fit: contain;
    display: block;
    margin-right: auto;
    margin-left: auto;
    height: 100% !important;
    width: 100% !important;
  }

  &__action {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__controllers {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 747px) {
      max-width: 500px;
    }
  }

  &__controllers-row {
    display: flex;
    width: min-content;
    margin-top: 20px;
  }

  &__controllers-file {
    width: 484px;
    @media (max-width: 1366px) {
      width: 100%;
    }
  }

  &__controllers-range {
    display: flex;
    align-items: center;
    height: 27px;
    font-size: 20px;
    line-height: 20px;
    &::before {
      margin-right: 10px;
      content: '–';
      width: 10px;
      color: $color-white;
      padding-bottom: 2px;
    }
    &::after {
      margin-left: 10px;
      content: '+';
      width: 10px;
      color: $color-white;
      padding-bottom: 4px;
    }
  }

  &__controllers-label {
    margin-right: 20px;
    font-size: 18px;
    font-weight: $font--light;
    line-height: 1;
    @media (max-width: 1366px) {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      margin-right: 16px;
    }
  }

  &__controllers-rotate {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__rotate-control {
    cursor: pointer;
    display: flex;
    gap: 4px;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    color: $color-white;
    text-align: center;
    font-weight: 400;
    &:last-child {
      button {
        transform: scale(1, 1);
      }
    }
  }

  &__controllers-rotate-btn {
    width: 20px;
    height: 22px;
    padding: 0;
    margin-left: 10px;
    border-width: 0;
    font-size: 18px;
    line-height: 1;
    flex-shrink: 0;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='17' viewBox='0 0 13 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.45077 6.13107C7.05067 6.23828 6.81323 6.64953 6.92044 7.04963C7.02764 7.44973 7.43889 7.68717 7.83899 7.57996L11.9371 6.48189C12.3372 6.37468 12.5746 5.96343 12.4674 5.56333L11.3693 1.46525C11.2621 1.06515 10.8509 0.827715 10.4508 0.934922C10.0507 1.04213 9.81323 1.45338 9.92044 1.85348L10.4799 3.94126C7.95871 2.70101 4.82443 3.12958 2.72703 5.22698C0.0909905 7.86302 0.0909901 12.1369 2.72703 14.7729C5.36307 17.409 9.63693 17.409 12.273 14.7729C12.5659 14.48 12.5659 14.0052 12.273 13.7123C11.9801 13.4194 11.5052 13.4194 11.2123 13.7123C9.16206 15.7625 5.83794 15.7625 3.78769 13.7123C1.73744 11.662 1.73744 8.33789 3.78769 6.28764C5.49275 4.58258 8.07883 4.29551 10.0806 5.42642L7.45077 6.13107Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    overflow: visible;
    cursor: pointer;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      transform: scale(1, -1) rotate(174deg);
    }
  }

  &__controllers-preview {
    padding: 12px 16px;
    font-size: 16px;
    width: 140px;
    font-weight: 500;
    line-height: 1.2;
    color: $color-white;
    border-radius: 8px;
    &_white {
      background: $color-white;
      color: $color-black;
    }
    @media (max-width: 747px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__previews {
    display: flex;
  }

  &__preview {
    width: 84px;
    height: 84px;
    margin-left: 20px;
    overflow: hidden;

    &:first-child {
      margin-left: 0;
    }

    &--sm {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    @media (max-width: 1366px) {
      display: none;
    }
  }

  &__preview-img {
    width: 100%;
    height: auto;
  }
}
